import {ElMessage} from "element-plus";
import {createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw} from "vue-router";
import {config, module, Router, storage} from "/@/cool";
import {isArray} from "lodash-es";
import {useBase} from "/$/base";
import {Loading} from "../utils";
import {isWxReDirect} from "../utils/tools";
import {routeMap} from "./map";

// 扫描文件
const files = import.meta.glob(["/src/modules/*/{views,pages}/**/*", "!**/components"]);

// 默认路由
const routes: RouteRecordRaw[] = [
	{
		path: "/",
		name: "index",
		component: () => import("/$/base/layout/index.vue"),
		children: [
			{
				path: "",
				name: "home",
				component: config.app.router.home
			}
		]
	}
];

// 创建路由器
const router = createRouter({
	history: config.app.router.mode == "history" ? createWebHistory() : createWebHashHistory(),
	routes
}) as Router;

// 组件加载后
router.beforeResolve(() => {
	setTimeout(() => {
		Loading.close();
	}, 100);
});

// 添加试图，页面路由
router.append = function (data) {
	const list = isArray(data) ? data : [data];

	list.forEach((d) => {
		if (!d.name) {
			d.name = d.path.substring(1);
		}

		if (!d.meta) {
			d.meta = {};
		}

		if (!d.component) {
			const url = d.viewPath;

			if (url) {
				if (url.indexOf("http") == 0) {
					if (d.meta) {
						d.meta.iframeUrl = url;
					}

					d.component = () => import(`/$/base/views/frame.vue`);
				} else {
					d.component = files["/src/" + url.replace("cool/", "")];
				}
			} else {
				d.redirect = "/404";
			}
		}

		d.meta.dynamic = true;

		if (d.isPage) {
			router.addRoute(d);
		} else {
			router.addRoute("index", d);
		}
	});
};

// 清空路由
router.clear = function () {
	const rs = router.getRoutes();

	rs.forEach((e) => {
		if (e.name) {
			router.removeRoute(e.name);
		}
	});
};

// 找路由
router.find = function (path: string) {
	return router.getRoutes().find((e) => e.path == path);
};

let lock = false;

// 错误监听
router.onError((err: any) => {
	if (!lock) {
		lock = true;

		ElMessage.error("页面不存在或者未配置！");
		console.error(err);

		setTimeout(() => {
			lock = false;
		}, 0);
	}
});

// 注册
async function register(path: string) {
	// 当前路由是否存在
	const f = Boolean(router.find(path));

	if (!f) {
		const {menu, oem} = useBase();

		// 等待加载
		await Loading.wait();

		// 待注册列表
		const list: any[] = [];

		// 菜单数据
		menu.routes.find((e) => {
			list.push({
				...e,
				isPage: e.viewPath?.includes("/pages")
			});
		});

		// 模块数据
		module.list.forEach((e) => {
			if (e.views) {
				list.push(...e.views);
			}

			if (e.pages) {
				list.push(
					...e.pages.map((d) => {
						return {
							...d,
							isPage: true
						};
					})
				);
			}
		});

		// 需要注册的路由
		const r = list.find((e) => e.path == path);

		if (r) {
			router.append(r);
		}
	}

	return {route: router.find(path), isReg: !f};
}

function getMenuFolder(group: any[], name = "拍照管理") {
	return group.find((e: any) => e.name == name);
}

function getMenu(group: any[], folderName = "拍照管理", menuName = "现场拍照") {
	return getMenuFolder(group, folderName)?.children?.find((e: any) => e.name == menuName);
}

function getTakephotoMenu(group: any[]) {
	return getMenu(group, "拍照管理", "现场拍照");
}

function getTakephotoOrder(group: any[]) {
	return getMenu(group, "拍照管理", "拍照订单");
}

function getShoppingCart(group: any[]) {
	return getMenu(group, "物料商城", "我要采购");
}

function getDealerMenu(group: any[]) {
	return getMenu(group, "经销管理", "经销商管理");
}

function getDealerShopMenu(group: any[]) {
	return getMenu(group, "经销管理", "门店管理");
}

function getPathByGroup(group: any[]): string {
	const takephoto = getTakephotoMenu(group);
	if (takephoto) {
		return takephoto.path;
	}
	const dealer = getDealerMenu(group);
	if (dealer) {
		return dealer.path;
	}
	const dealerShop = getDealerShopMenu(group);
	if (dealerShop) {
		return dealerShop.path;
	}
	const order = getTakephotoOrder(group);
	if (order) {
		return order.path;
	}

	const cart = getShoppingCart(group);
	if (cart) {
		return cart.path;
	}

	return "";
}

// 路由守卫
router.beforeEach(async (to, from, next) => {
	// 如果是相同的路由，则不执行后续操作
	 if (to.path === from?.path && to.path != "/") {
    return next(false); // 阻止导航
  }
	// 数据缓存
	const {user, process, menu, oem} = useBase();

	// 预先注册路由
	const {isReg, route} = await register(to.path);
	const group = menu.group;

	//保存最后一次访问的页面
	if (to.path.indexOf("login") === -1 && window.location.href.indexOf("code") === -1) {
		storage.set("lastPagePath", window.location.href);
	}

	if (routeMap[to.path]) {
		return next(routeMap[to.path]);
	}

	// 组件不存在、路由不存在
	if (!route?.components) {
		next(user.token ? "/404" : "/login");
		return;
	}

	// 注册后重定向
	if (isReg) {
		next({...to, path: route.path});
		return;
	}

	if (to.path == "/token") {
		next();
		return;
	}

	// 登录成功
	if (user.token) {
		if (!user.info?.login_openid) {
			if (to.fullPath.indexOf("/my/info") === -1) {
				 next("/my/info");
				 return;
			}
		}

		// 在登录页
		if (to.path.includes("/login")) {
			// Token 未过期
			if (!storage.isExpired("token")) {
				const path: string = getPathByGroup(group);
				if (path) {
					 next(path);
					 return
				}
				// 回到首页
				next(oem.defaultPage());
				return;
			}
		} else {
			if (to.path == "/") {
				const path: string = getPathByGroup(group);
				if (path) {
					 next(path);
					 return
				}
				let temflag = isWxReDirect(next);
				if (!temflag) {
					 next(oem.defaultPage());
					 return;
				}
			}

			// 添加路由进程
			process.add(to);
		}
	} else {
		// 忽略部分 Token 验证
		if (!config.ignore.token.find((e) => to.path == e)) {
			if (to.path == "/initCheck") {
				return next();
			} else {
				return next("/login");
			}
		}
	}

	next();
});
router.afterEach((to, from) => {
	//保存最后一次访问的页面
	if (to.path.indexOf("login") === -1 && window.location.href.indexOf("code") === -1) {
		storage.set("lastPagePath", window.location.href)
	}
});
export {router};
